<template>
  <div>
    <div class="search">
      <van-nav-bar title="返回" left-arrow @click-left="onClickLeft" />
      <div class="main">
        <van-search class="input" v-model="value" focus shape="round" placeholder="请输入点位名称" @input="onInput" />
        <!-- <van-tabs v-model="active" @click="table" sticky>
          <van-tab v-for="(item, index) in title" :key="index" :title="item">
            <div
              class="line"
              v-for="(item, index) in list"
              :key="index"
              v-show="item.type == active + 1"
              @click="column(item)"
            >
              <div style="display: flex; align-items: center">
                <div class="img">
                  <img :src="imgUrl[active]" alt="" />
                </div>
                <span>{{
                  item.scenicSpotName ||
                  item.deliciousFoodName ||
                  item.lodgingName ||
                  item.cultureName ||
                  item.specialtyName ||
                  item.name
                }}</span>
              </div>
            </div>
          </van-tab>
        </van-tabs> -->
      </div>
    </div>
    <div class="xuanfu" v-if="valueData[0]">
          <div class="line" v-for="(item, index) in valueData" :key="index" @click="column(item)">
            <div style="display: flex; align-items: center">
              <div class="img">
                <img :src="imgUrl[item.type - 1]" alt="" />
              </div>
              <span>{{
              item.scenicSpotName ||
              item.deliciousFoodName ||
              item.lodgingName ||
              item.cultureName ||
              item.specialtyName ||
              item.name
              }}</span>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "search",
  data() {
    return {
      value: "",
      active: 0,
      title: ["景点", "美食", "住宿", "文化", "特产"],
      list: [],
      valueData: [],
      imgUrl: [
        require("../../assets/icon/home/jingdian.png"),
        require("../../assets/icon/home/meishi.png"),
        require("../../assets/icon/home/zhusu.png"),
        require("../../assets/icon/home/wenhua.png"),
        require("../../assets/icon/home/techan.png"),
      ],
    };
  },
  created() {
    this.searching("");
  },
  methods: {
    ...mapMutations(["sethomeData"]),
    onClickLeft() {
      this.$router.go("-1");
    },
    // table导航栏
    table(i) {
      this.active = i;
    },
    // 点击栏目
    column(data) {
      console.log(data);
      this.$router.push({
        path: "/Home",
        query: { ...data },
      });
    },

    onInput(value) {
      console.log(value,'输入的内容');
      if (value == "") {
        this.valueData = [];
      }
      this.searching(value);
    },
    searching(value) {
      let params = new FormData();
      let abbreviation = JSON.parse(
        sessionStorage.getItem("abbreviation")
      ).travelAbbreviation;
      params.append("name", value);
      params.append("abbreviation", abbreviation);
      let data = {
        name:value,
        abbreviation:abbreviation
      }
      this.$axios.post("/api/map/getSearchData", data).then((res) => {
        if (value == "") {
          console.log(value,'value为空');
          this.list = res.data;
        } else {
          this.valueData = res.data;
          console.log(res);
          console.log (value,'value不为空');
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  padding-top: 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.main {
  width: 70%;
  // background-color: aqua;
  // overflow: hidden;
  .input {
    height: 4vh;
    border-radius: 20px;
  }
}
/** 导航栏 */
/deep/ .van-nav-bar {
  width: 25%;
  // position: fixed;
  height: 4vh;
  overflow: hidden;
  // top: 0;
  // z-index: 2;
  i {
    font-size: 0.5rem;
    color: #000;
    margin-top: -0.01rem;
  }
  .van-nav-bar__content {
    height: 100%;
  }
  .van-nav-bar__title {
    font-weight: 500;
    font-size: 0.32rem;
    color: #000;
    margin-left: 0.9rem;
  }
}
// 搜索层
.xuanfu {
  overflow: auto;
  position: absolute;
  top:5vh;
  width: 100%;
  height: 87vh;
  min-height: 4rem;
  background-color: #fff;
  z-index: 100;
  // border: 1px solid ;
}
/** 选项卡 */
/deep/ .van-tabs__wrap {
  width: 100%;
  height: 0.7rem;
  box-shadow: 0px 3px 4px 0px #e5e5e5;
  // position: fixed;
  // top: 0.94rem;
  z-index: 1;
  margin-bottom: 0.2rem;
  .van-tab {
    font-size: 0.28rem;
    color: #121212;
  }
  .van-tabs__line {
    width: 0.5rem;
    height: 0.06rem;
    border-radius: 0.06rem;
    bottom: 0.32rem;
    background: #249ede;
  }
}
/deep/ .van-tab__pane {
  height: 75vh;
  overflow: auto;
}
.line {
  height: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.2rem;
  margin: 0.2rem 0;
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.1rem;
    width: 0.6rem;
    height: 0.6rem;
    img {
      width: 0.6rem;
      height: 0.6rem;
      // filter: drop-shadow(30px 0 0 green);
      filter: brightness(150%);
      border-radius: 50%;
    }
  }
  span {
    font-size: 0.28rem;
    color: #121212;
  }
  p {
    font-size: 0.24rem;
    color: rgba(18, 18, 18, 0.5);
  }
}
</style>